/* autoprefixer flex: autoplace */
.logo-sections {
  display: flex;
  align-items: center;
}

.card-header-success {
  background: #e2e2e266 no-repeat 95% 50%;

  & .row {
    width: 100%;
  }
}

.master-logo {
  display: -webkit-box;
  background: #e2e2e266 url('/assets/mastercard_2.png') no-repeat 95% 50%;
  width: 42px;
  height: 42px;
}

.qi-logo {
  display: -webkit-box;
  background: #e2e2e266 url('/assets/qi.png') no-repeat 95% 50%;
  width: 32px;
  height: 32px;
}
