/* autoprefixer flex: autoplace */
.form-header {
  // d-flex flex-column justify-content-start
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

[dir='rtl'] {
  input[type='tel'],
  .force-ltr__input {
    direction: ltr;
    padding-left: 10%;
  }
  .card-form__col {
    margin-left: 35px;
    margin-right: 0;
  }
  .card-form__col.-cvv {
    margin: 0;
  }
}

.form-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
}

.rtf {
  box-sizing: border-box;
  margin: 25px;
  position: fixed;
  white-space: nowrap;
  z-index: 9998;
  padding-left: 0;
  list-style: none;
  &.open {
    .rtf--mb {
      > * {
        transform-origin: center center;
        transform: rotate(315deg);
        transition: ease-in-out transform 0.2s;
      }
      > ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }
    .rtf--ab__c {
      &:hover {
        > span {
          transition: ease-in-out opacity 0.2s;
          opacity: 0.9;
        }
      }
      > span.always-show {
        transition: ease-in-out opacity 0.2s;
        opacity: 0.9;
      }
      &:nth-child(1) {
        transform: translateY(-60px) scale(1);
        transition-delay: 0.03s;
        &.top {
          transform: translateY(60px) scale(1);
        }
      }
      &:nth-child(2) {
        transform: translateY(-120px) scale(1);
        transition-delay: 0.09s;
        &.top {
          transform: translateY(120px) scale(1);
        }
      }
      &:nth-child(3) {
        transform: translateY(-180px) scale(1);
        transition-delay: 0.12s;
        &.top {
          transform: translateY(180px) scale(1);
        }
      }
      &:nth-child(4) {
        transform: translateY(-240px) scale(1);
        transition-delay: 0.15s;
        &.top {
          transform: translateY(240px) scale(1);
        }
      }
      &:nth-child(5) {
        transform: translateY(-300px) scale(1);
        transition-delay: 0.18s;
        &.top {
          transform: translateY(300px) scale(1);
        }
      }
      &:nth-child(6) {
        transform: translateY(-360px) scale(1);
        transition-delay: 0.21s;
        &.top {
          transform: translateY(360px) scale(1);
        }
      }
    }
  }
}

.rtf--mb__c {
  padding: 25px;
  margin: -25px;
  *:last-child {
    margin-bottom: 0;
  }
  &:hover {
    > span {
      transition: ease-in-out opacity 0.2s;
      opacity: 0.9;
    }
  }
  > span.always-show {
    transition: ease-in-out opacity 0.2s;
    opacity: 0.9;
  }
  > span {
    opacity: 0;
    transition: ease-in-out opacity 0.2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 6px;
    margin-left: 4px;
    background: rgba(0, 0, 0, 0.75);
    padding: 2px 4px;
    border-radius: 2px;
    color: white;
    font-size: 13px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    &.right {
      right: 100%;
    }
  }
}

.rtf--mb {
  height: 56px;
  width: 56px;
  z-index: 9999;

  background-color: #263230;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
  font-size: 18px;
  > * {
    transition: ease-in-out transform 0.2s;
  }
}

.rtf--ab__c {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: (-10px) 0;
  transition: ease-in-out transform 0.2s;
  > span {
    opacity: 0;
    transition: ease-in-out opacity 0.2s;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    margin-right: 6px;
    background: rgba(0, 0, 0, 0.75);
    padding: 2px 4px;
    border-radius: 2px;
    color: white;
    font-size: 13px;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
    &.right {
      right: 100%;
    }
  }
  &:nth-child(1) {
    transform: translateY(-60px) scale(0);
    transition-delay: 0.21s;
    &.top {
      transform: translateY(60px) scale(0);
    }
  }
  &:nth-child(2) {
    transform: translateY(-120px) scale(0);
    transition-delay: 0.18s;
    &.top {
      transform: translateY(120px) scale(0);
    }
  }
  &:nth-child(3) {
    transform: translateY(-180px) scale(0);
    transition-delay: 0.15s;
    &.top {
      transform: translateY(180px) scale(0);
    }
  }
  &:nth-child(4) {
    transform: translateY(-240px) scale(0);
    transition-delay: 0.12s;
    &.top {
      transform: translateY(240px) scale(0);
    }
  }
  &:nth-child(5) {
    transform: translateY(-300px) scale(0);
    transition-delay: 0.09s;
    &.top {
      transform: translateY(300px) scale(0);
    }
  }
  &:nth-child(6) {
    transform: translateY(-360px) scale(0);
    transition-delay: 0.03s;
    &.top {
      transform: translateY(360px) scale(0);
    }
  }
}

.rtf--ab {
  height: 48px;
  width: 48px;
  background-color: #aaaaaa;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
  margin-right: 4px;
  font-size: 16px;
  z-index: 10000;
}
