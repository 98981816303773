/* autoprefixer flex: autoplace */
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap');

body {
  zoom: 0.8;
  background: #ddeefc;
  font-family: 'Cairo', sans-serif;
  font-size: 1rem;
  line-height: 1;
}

* {
  box-sizing: border-box;

  &:focus {
    outline: none;
  }
}

.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    // box-shadow: 3px 13px 30px 0px rgba(21, 34, 67, 0.2);
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px;
    padding-top: 150px;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: 'Cairo', sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

[dir='rtl'] {
  .card-input {
    &__input {
      margin-left: 15px !important;
      margin-right: 0 !important;
      &.-select {
        background-position: 10% center !important;
        padding-left: 30px !important;
        padding-right: 15px !important;
      }
      &.user_type {
        background-position: 5% center !important;
      }
    }
  }
}

.card-input {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: inherit;
    user-select: none;
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: 'Cairo', sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }

    &.-select {
      -webkit-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
    &.user_type {
      background-position: 95% center !important;
    }
  }
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}

.success-payment {
  section {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .img {
    position: absolute;
    right: 0px;
    bottom: 0px;
    margin: 25px;
  }
  .success-payment-img {
    position: absolute;
    right: 0px;
    bottom: 0px;
  }
  @media screen and (max-height: 455px) {
    .success-payment-img {
      bottom: -100px;
    }
  }

  @media screen and (max-height: 350px) {
    .success-payment-img {
      bottom: -200px;
    }
  }

  .fa-lock {
    background: #273746;
    color: #fff;
    line-height: 2.1;
    padding: 8%;
    width: 30px;
    text-align: center;
  }

  .footerImage {
    display: flex;

    & img {
      margin: 0 8px 8px 0;
    }
  }

  .continue-footer {
    display: flex;
  }

  @media (min-width: 360px) and (max-width: 767px) {
    .col-md-4 .form-group {
      margin-bottom: 0;
    }

    .mt-2 {
      margin-top: 0.3rem !important;
    }
  }
}
.app-footer {
  // position: absolute;
  right: 0;
  bottom: 0;
  margin-left: auto !important;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .app-footer {
    position: relative;
  }
}
.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.not-visible {
  visibility: hidden;
}

.pay-now-btn {
  &:disabled {
    border-color: grey;
    color: grey;
    pointer-events: none;
  }
}

.app-footer-container {
  direction: ltr;
}

.swal-footer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}
[dir='rtl'] .swal-footer {
  justify-content: flex-end;
  flex-direction: row;
}

.swal-button--danger,
.swal-button--danger:not([disabled]):hover {
  background-color: #ffc107 !important;
}

.swal-button--cancel {
  background-color: #e64942 !important;
  color: #fff !important;
}
