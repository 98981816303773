@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@400;600;700&display=swap);
/* autoprefixer flex: autoplace */
body {
  zoom: 0.8;
  background: #ddeefc;
  font-family: "Cairo", sans-serif;
  font-size: 1rem;
  line-height: 1;
}

* {
  box-sizing: border-box;
}
*:focus {
  outline: none;
}

.wrapper {
  min-height: 100vh;
  display: flex;
  padding: 50px 15px;
}
@media screen and (max-width: 700px), (max-height: 500px) {
  .wrapper {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;
}
@media screen and (max-width: 576px) {
  .card-form {
    margin: 0 auto;
  }
}
.card-form__inner {
  background: #fff;
  box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
  border-radius: 10px;
  padding: 35px;
  padding-top: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__inner {
    padding: 25px;
    padding-top: 165px;
  }
}
@media screen and (max-width: 360px) {
  .card-form__inner {
    padding: 15px;
    padding-top: 165px;
  }
}
.card-form__row {
  display: flex;
  align-items: flex-start;
}
@media screen and (max-width: 480px) {
  .card-form__row {
    flex-wrap: wrap;
  }
}
.card-form__col {
  flex: auto;
  margin-right: 35px;
}
.card-form__col:last-child {
  margin-right: 0;
}
@media screen and (max-width: 480px) {
  .card-form__col {
    margin-right: 0;
    flex: unset;
    width: 100%;
    margin-bottom: 20px;
  }
  .card-form__col:last-child {
    margin-bottom: 0;
  }
}
.card-form__col.-cvv {
  max-width: 150px;
}
@media screen and (max-width: 480px) {
  .card-form__col.-cvv {
    max-width: initial;
  }
}
.card-form__group {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
.card-form__group .card-input__input {
  flex: 1 1;
  margin-right: 15px;
}
.card-form__group .card-input__input:last-child {
  margin-right: 0;
}
.card-form__button {
  width: 100%;
  height: 55px;
  background: #2364d2;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  font-weight: 500;
  font-family: "Cairo", sans-serif;
  box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
  color: #fff;
  margin-top: 20px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-form__button {
    margin-top: 10px;
  }
}

.card-list {
  margin-bottom: -130px;
}
@media screen and (max-width: 480px) {
  .card-list {
    margin-bottom: -120px;
  }
}

[dir=rtl] .card-input__input {
  margin-left: 15px !important;
  margin-right: 0 !important;
}
[dir=rtl] .card-input__input.-select {
  background-position: 10% center !important;
  padding-left: 30px !important;
  padding-right: 15px !important;
}
[dir=rtl] .card-input__input.user_type {
  background-position: 5% center !important;
}

.card-input {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.card-input__label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: 500;
  color: #1a3b5d;
  width: 100%;
  display: inherit;
  -webkit-user-select: none;
          user-select: none;
}
.card-input__input {
  width: 100%;
  height: 50px;
  border-radius: 5px;
  box-shadow: none;
  border: 1px solid #ced6e0;
  transition: all 0.3s ease-in-out;
  font-size: 18px;
  padding: 5px 15px;
  background: none;
  color: #1a3b5d;
  font-family: "Cairo", sans-serif;
}
.card-input__input:hover, .card-input__input:focus {
  border-color: #3d9cff;
}
.card-input__input:focus {
  box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
}
.card-input__input.-select {
  -webkit-appearance: none;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
  background-size: 12px;
  background-position: 90% center;
  background-repeat: no-repeat;
  padding-right: 30px;
}
.card-input__input.user_type {
  background-position: 95% center !important;
}

.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all 0.3s ease-in-out;
}
@media screen and (min-width: 500px) {
  .github-btn:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}
@media screen and (max-width: 700px) {
  .github-btn {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;
  }
  .github-btn:active {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
  }
}

.success-payment section {
  display: flex;
  justify-content: center;
  align-items: center;
}
.success-payment input[type=number]::-webkit-inner-spin-button,
.success-payment input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.success-payment .img {
  position: absolute;
  right: 0px;
  bottom: 0px;
  margin: 25px;
}
.success-payment .success-payment-img {
  position: absolute;
  right: 0px;
  bottom: 0px;
}
@media screen and (max-height: 455px) {
  .success-payment .success-payment-img {
    bottom: -100px;
  }
}
@media screen and (max-height: 350px) {
  .success-payment .success-payment-img {
    bottom: -200px;
  }
}
.success-payment .fa-lock {
  background: #273746;
  color: #fff;
  line-height: 2.1;
  padding: 8%;
  width: 30px;
  text-align: center;
}
.success-payment .footerImage {
  display: flex;
}
.success-payment .footerImage img {
  margin: 0 8px 8px 0;
}
.success-payment .continue-footer {
  display: flex;
}
@media (min-width: 360px) and (max-width: 767px) {
  .success-payment .col-md-4 .form-group {
    margin-bottom: 0;
  }
  .success-payment .mt-2 {
    margin-top: 0.3rem !important;
  }
}

.app-footer {
  right: 0;
  bottom: 0;
  margin-left: auto !important;
}

@media screen and (max-width: 700px), (max-height: 500px) {
  .app-footer {
    position: relative;
  }
}
.disabled {
  pointer-events: none;
  cursor: none;
  opacity: 0.5;
}

.not-visible {
  visibility: hidden;
}

.pay-now-btn:disabled {
  border-color: grey;
  color: grey;
  pointer-events: none;
}

.app-footer-container {
  direction: ltr;
}

.swal-footer {
  display: flex;
  justify-content: flex-start;
  flex-direction: row-reverse;
}

[dir=rtl] .swal-footer {
  justify-content: flex-end;
  flex-direction: row;
}

.swal-button--danger,
.swal-button--danger:not([disabled]):hover {
  background-color: #ffc107 !important;
}

.swal-button--cancel {
  background-color: #e64942 !important;
  color: #fff !important;
}
/* autoprefixer flex: autoplace */
.direction-enforced {
  direction: ltr !important;
}

.card-header-control {
  width: 100%;
  zoom: 1.3;
}

[dir=rtl] .qi-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/qic.jpg") no-repeat 5% 50% !important;
}

[dir=rtl] .master-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/mastercard_2.png") no-repeat 5% 50% !important;
}

[dir=rtl] .aqsati-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/aqsati-logo.png") no-repeat 5% 50% !important;
  background-size: 20px 20px !important;
}

.head2.qi-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/qic.jpg") no-repeat 95% 50%;
}
.head2.master-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/mastercard_2.png") no-repeat 95% 50%;
}
.head2.aqsati-card {
  background: rgba(226, 226, 226, 0.4) url("/assets/aqsati-logo.png") no-repeat 95% 50%;
  background-size: 20px 20px;
}
.head2 .card-header-control {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.head2 .card-header-control input {
  margin: 0px;
}
.head2 .card-header-control label {
  margin: 5px;
}

.card-header text {
  font-size: 12px;
  margin-top: 8px;
}

.imgs {
  /* width: 21%; */
  margin-left: 290px;
}

.imgsy {
  /* width: 21%; */
  margin-left: 328px;
}

.paybtn {
  width: 100%;
}

.u2 {
  margin: 2px;
}

.u {
  margin: 8px;
}

#cvv {
  background: url("/assets/cvv.png") no-repeat 85%;
}

[dir=rtl] #cvv {
  background: url("/assets/cvv.png") no-repeat 15%;
}

.name {
  background: url("/assets/man.png") no-repeat 95%;
}

.pin {
  background: url("/assets/lock.png") no-repeat 95%;
}

[dir=rtl] #cardnumber {
  background: url("/assets/lock.png") no-repeat 5% !important;
}

#cardnumber {
  background: url("/assets/lock.png") no-repeat 95%;
}
#cardnumber .u22 {
  margin-left: 323px;
}

#inputy {
  background: url("/assets/mastercard_2.png") no-repeat;
}

.form-check-label {
  margin-left: 5px;
  margin-right: 5px;
}

#h {
  background: none;
}

#cardname {
  background: url("/assets/man.png") no-repeat 95%;
}

[dir=rtl] #cardname {
  background: url("/assets/man.png") no-repeat 5% !important;
}

.card-item {
  max-width: 450px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }
}
@media screen and (max-width: 360px) {
  .card-item {
    height: 180px;
  }
}
.card-item.-active .card-item__side.-front {
  -webkit-transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
          transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
}
.card-item.-active .card-item__side.-back {
  -webkit-transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
          transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
}
.card-item__focus {
  position: absolute;
  z-index: 3;
  border-radius: 5px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
  opacity: 0;
  pointer-events: none;
  overflow: hidden;
  border: 2px solid rgba(255, 255, 255, 0.65);
}
.card-item__focus:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background: rgb(8, 20, 47);
  height: 100%;
  border-radius: 5px;
  -webkit-filter: blur(25px);
          filter: blur(25px);
  opacity: 0.5;
}
.card-item__focus.-active {
  opacity: 1;
}
.card-item__side {
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 16px 0 rgba(14, 42, 84, 0.55);
  -webkit-transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
          transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
  -webkit-transform-style: preserve-3d;
          transform-style: preserve-3d;
  transition: all 0.8s cubic-bezier(0.42, 0, 0, 1.05);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  height: 100%;
}
.card-item__side.-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
          transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
  z-index: 2;
  padding: 0;
  height: 100%;
}
.card-item__side.-back .card-item__cover {
  -webkit-transform: rotateY(-180deg);
          transform: rotateY(-180deg);
}
.card-item__bg {
  max-width: 100%;
  display: block;
  max-height: 100%;
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.card-item__cover {
  height: 100%;
  background-color: #1c1d27;
  position: absolute;
  height: 100%;
  background-color: #1c1d27;
  left: 0;
  top: 0;
  width: 100%;
  border-radius: 15px;
  overflow: hidden;
}
.card-item__cover:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(6, 2, 29, 0.45);
}
.card-item__top {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 40px;
  padding: 0 10px;
}
@media screen and (max-width: 480px) {
  .card-item__top {
    margin-bottom: 25px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__top {
    margin-bottom: 15px;
  }
}
.card-item__chip {
  width: 60px;
}
@media screen and (max-width: 480px) {
  .card-item__chip {
    width: 50px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__chip {
    width: 40px;
  }
}
.card-item__type {
  height: 45px;
  position: relative;
  display: flex;
  justify-content: flex-end;
  max-width: 100px;
  margin-left: auto;
  width: 100%;
}
@media screen and (max-width: 480px) {
  .card-item__type {
    height: 40px;
    max-width: 90px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__type {
    height: 30px;
  }
}
.card-item__typeImg {
  max-width: 100%;
  object-fit: contain;
  max-height: 100%;
  object-position: top right;
}
.card-item__info {
  color: #fff;
  width: 100%;
  max-width: calc(100% - 85px);
  padding: 10px 15px;
  font-weight: 500;
  display: block;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__info {
    padding: 10px;
  }
}
.card-item__holder {
  opacity: 0.7;
  font-size: 13px;
  margin-bottom: 6px;
}
@media screen and (max-width: 480px) {
  .card-item__holder {
    font-size: 12px;
    margin-bottom: 5px;
  }
}
.card-item__wrapper {
  font-family: "Source Code Pro", monospace;
  padding: 25px 15px;
  position: relative;
  z-index: 4;
  height: 100%;
  text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
  -webkit-user-select: none;
          user-select: none;
}
@media screen and (max-width: 480px) {
  .card-item__wrapper {
    padding: 20px 10px;
  }
}
.card-item__name {
  font-size: 18px;
  line-height: 1;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: uppercase;
}
@media screen and (max-width: 480px) {
  .card-item__name {
    font-size: 16px;
  }
}
.card-item__nameItem {
  display: inline-block;
  min-width: 8px;
  position: relative;
}
.card-item__number {
  font-weight: 500;
  line-height: 1;
  color: #fff;
  font-size: 27px;
  margin-bottom: 35px;
  display: inline-block;
  padding: 10px 15px;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__number {
    font-size: 21px;
    margin-bottom: 15px;
    padding: 10px 10px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__number {
    font-size: 19px;
    margin-bottom: 10px;
    padding: 10px 10px;
  }
}
.card-item__numberItem {
  width: 16px;
  display: inline-block;
}
.card-item__numberItem.-active {
  width: 30px;
}
@media screen and (max-width: 480px) {
  .card-item__numberItem {
    width: 13px;
  }
  .card-item__numberItem.-active {
    width: 16px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__numberItem {
    width: 12px;
  }
  .card-item__numberItem.-active {
    width: 8px;
  }
}
.card-item__content {
  color: #fff;
  display: flex;
  align-items: flex-start;
}
.card-item__date {
  flex-wrap: wrap;
  font-size: 18px;
  margin-left: auto;
  padding: 10px;
  display: inline-flex;
  width: 80px;
  white-space: nowrap;
  flex-shrink: 0;
  cursor: pointer;
}
@media screen and (max-width: 480px) {
  .card-item__date {
    font-size: 16px;
  }
}
.card-item__dateItem {
  position: relative;
}
.card-item__dateItem span {
  width: 22px;
  display: inline-block;
}
.card-item__dateTitle {
  opacity: 0.7;
  font-size: 13px;
  width: 100%;
  padding-bottom: 0;
  margin-bottom: 0;
}
.card-item__band {
  background: rgba(0, 0, 19, 0.8);
  width: 100%;
  height: 50px;
  margin-top: 30px;
  position: relative;
  z-index: 2;
}
@media screen and (max-width: 480px) {
  .card-item__band {
    margin-top: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__band {
    height: 40px;
    margin-top: 10px;
  }
}
.card-item__cvv {
  text-align: right;
  position: relative;
  z-index: 2;
  padding: 15px;
}
.card-item__cvv .card-item__type {
  opacity: 0.7;
}
@media screen and (max-width: 360px) {
  .card-item__cvv {
    padding: 10px 15px;
  }
}
.card-item__cvvTitle {
  padding-right: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-bottom: 5px;
}
.card-item__cvvBand {
  height: 45px;
  background: #fff;
  margin-bottom: 30px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 10px;
  color: #1a3b5d;
  font-size: 18px;
  border-radius: 4px;
  box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);
}
@media screen and (max-width: 480px) {
  .card-item__cvvBand {
    height: 40px;
    margin-bottom: 20px;
  }
}
@media screen and (max-width: 360px) {
  .card-item__cvvBand {
    margin-bottom: 15px;
  }
}

.slide-fade-right-enter {
  opacity: 0;
  -webkit-transform: translateX(10px) rotate(45deg);
          transform: translateX(10px) rotate(45deg);
  pointer-events: nne;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  -webkit-transform: translateX(0px) rotate(0deg);
          transform: translateX(0px) rotate(0deg);
  position: relative;
  opacity: 100;
}

.slide-fade-right-exit {
  -webkit-transform: translateX(0px) rotate(0deg);
          transform: translateX(0px) rotate(0deg);
  pointer-events: none;
}

.slide-fade-right-exit-active {
  opacity: 0;
  transition: all 0.25s ease-in-out;
  -webkit-transform: translateX(0px) rotate(-80deg);
          transform: translateX(0px) rotate(-80deg);
  position: relative;
}

.slide-fade-up-enter {
  -webkit-transform: translateY(15px);
          transform: translateY(15px);
  pointer-events: none;
  opacity: 0;
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  position: relative;
  opacity: 1;
}

.slide-fade-up-enter-done {
  opacity: 1;
}

.slide-fade-up-exit {
  -webkit-transform: translateY(0px);
          transform: translateY(0px);
  pointer-events: none;
  opacity: 1;
}

.slide-fade-up-exit-active {
  transition: all 250ms ease-in-out;
  -webkit-transform: translateY(-15px);
          transform: translateY(-15px);
  position: relative;
  opacity: 0;
}

.slide-fade-up-exit-done {
  opacity: 0;
}
/* autoprefixer flex: autoplace */
.form-header {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

[dir=rtl] input[type=tel],
[dir=rtl] .force-ltr__input {
  direction: ltr;
  padding-left: 10%;
}
[dir=rtl] .card-form__col {
  margin-left: 35px;
  margin-right: 0;
}
[dir=rtl] .card-form__col.-cvv {
  margin: 0;
}

.form-footer {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 60px;
}

.rtf {
  box-sizing: border-box;
  margin: 25px;
  position: fixed;
  white-space: nowrap;
  z-index: 9998;
  padding-left: 0;
  list-style: none;
}
.rtf.open .rtf--mb > * {
  -webkit-transform-origin: center center;
          transform-origin: center center;
  -webkit-transform: rotate(315deg);
          transform: rotate(315deg);
  transition: ease-in-out transform 0.2s;
}
.rtf.open .rtf--mb > ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.rtf.open .rtf--ab__c:hover > span {
  transition: ease-in-out opacity 0.2s;
  opacity: 0.9;
}
.rtf.open .rtf--ab__c > span.always-show {
  transition: ease-in-out opacity 0.2s;
  opacity: 0.9;
}
.rtf.open .rtf--ab__c:nth-child(1) {
  -webkit-transform: translateY(-60px) scale(1);
          transform: translateY(-60px) scale(1);
  transition-delay: 0.03s;
}
.rtf.open .rtf--ab__c:nth-child(1).top {
  -webkit-transform: translateY(60px) scale(1);
          transform: translateY(60px) scale(1);
}
.rtf.open .rtf--ab__c:nth-child(2) {
  -webkit-transform: translateY(-120px) scale(1);
          transform: translateY(-120px) scale(1);
  transition-delay: 0.09s;
}
.rtf.open .rtf--ab__c:nth-child(2).top {
  -webkit-transform: translateY(120px) scale(1);
          transform: translateY(120px) scale(1);
}
.rtf.open .rtf--ab__c:nth-child(3) {
  -webkit-transform: translateY(-180px) scale(1);
          transform: translateY(-180px) scale(1);
  transition-delay: 0.12s;
}
.rtf.open .rtf--ab__c:nth-child(3).top {
  -webkit-transform: translateY(180px) scale(1);
          transform: translateY(180px) scale(1);
}
.rtf.open .rtf--ab__c:nth-child(4) {
  -webkit-transform: translateY(-240px) scale(1);
          transform: translateY(-240px) scale(1);
  transition-delay: 0.15s;
}
.rtf.open .rtf--ab__c:nth-child(4).top {
  -webkit-transform: translateY(240px) scale(1);
          transform: translateY(240px) scale(1);
}
.rtf.open .rtf--ab__c:nth-child(5) {
  -webkit-transform: translateY(-300px) scale(1);
          transform: translateY(-300px) scale(1);
  transition-delay: 0.18s;
}
.rtf.open .rtf--ab__c:nth-child(5).top {
  -webkit-transform: translateY(300px) scale(1);
          transform: translateY(300px) scale(1);
}
.rtf.open .rtf--ab__c:nth-child(6) {
  -webkit-transform: translateY(-360px) scale(1);
          transform: translateY(-360px) scale(1);
  transition-delay: 0.21s;
}
.rtf.open .rtf--ab__c:nth-child(6).top {
  -webkit-transform: translateY(360px) scale(1);
          transform: translateY(360px) scale(1);
}

.rtf--mb__c {
  padding: 25px;
  margin: -25px;
}
.rtf--mb__c *:last-child {
  margin-bottom: 0;
}
.rtf--mb__c:hover > span {
  transition: ease-in-out opacity 0.2s;
  opacity: 0.9;
}
.rtf--mb__c > span.always-show {
  transition: ease-in-out opacity 0.2s;
  opacity: 0.9;
}
.rtf--mb__c > span {
  opacity: 0;
  transition: ease-in-out opacity 0.2s;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-right: 6px;
  margin-left: 4px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
  font-size: 13px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.rtf--mb__c > span.right {
  right: 100%;
}

.rtf--mb {
  height: 56px;
  width: 56px;
  z-index: 9999;
  background-color: #263230;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
  font-size: 18px;
}
.rtf--mb > * {
  transition: ease-in-out transform 0.2s;
}

.rtf--ab__c {
  display: block;
  position: absolute;
  top: 0;
  right: 1px;
  padding: 10px 0;
  margin: -10px 0;
  transition: ease-in-out transform 0.2s;
}
.rtf--ab__c > span {
  opacity: 0;
  transition: ease-in-out opacity 0.2s;
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-right: 6px;
  background: rgba(0, 0, 0, 0.75);
  padding: 2px 4px;
  border-radius: 2px;
  color: white;
  font-size: 13px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
}
.rtf--ab__c > span.right {
  right: 100%;
}
.rtf--ab__c:nth-child(1) {
  -webkit-transform: translateY(-60px) scale(0);
          transform: translateY(-60px) scale(0);
  transition-delay: 0.21s;
}
.rtf--ab__c:nth-child(1).top {
  -webkit-transform: translateY(60px) scale(0);
          transform: translateY(60px) scale(0);
}
.rtf--ab__c:nth-child(2) {
  -webkit-transform: translateY(-120px) scale(0);
          transform: translateY(-120px) scale(0);
  transition-delay: 0.18s;
}
.rtf--ab__c:nth-child(2).top {
  -webkit-transform: translateY(120px) scale(0);
          transform: translateY(120px) scale(0);
}
.rtf--ab__c:nth-child(3) {
  -webkit-transform: translateY(-180px) scale(0);
          transform: translateY(-180px) scale(0);
  transition-delay: 0.15s;
}
.rtf--ab__c:nth-child(3).top {
  -webkit-transform: translateY(180px) scale(0);
          transform: translateY(180px) scale(0);
}
.rtf--ab__c:nth-child(4) {
  -webkit-transform: translateY(-240px) scale(0);
          transform: translateY(-240px) scale(0);
  transition-delay: 0.12s;
}
.rtf--ab__c:nth-child(4).top {
  -webkit-transform: translateY(240px) scale(0);
          transform: translateY(240px) scale(0);
}
.rtf--ab__c:nth-child(5) {
  -webkit-transform: translateY(-300px) scale(0);
          transform: translateY(-300px) scale(0);
  transition-delay: 0.09s;
}
.rtf--ab__c:nth-child(5).top {
  -webkit-transform: translateY(300px) scale(0);
          transform: translateY(300px) scale(0);
}
.rtf--ab__c:nth-child(6) {
  -webkit-transform: translateY(-360px) scale(0);
          transform: translateY(-360px) scale(0);
  transition-delay: 0.03s;
}
.rtf--ab__c:nth-child(6).top {
  -webkit-transform: translateY(360px) scale(0);
          transform: translateY(360px) scale(0);
}

.rtf--ab {
  height: 48px;
  width: 48px;
  background-color: #aaaaaa;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: none;
  border-radius: 50%;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  cursor: pointer;
  outline: none;
  padding: 0;
  -webkit-user-drag: none;
  font-weight: bold;
  color: #f1f1f1;
  margin-right: 4px;
  font-size: 16px;
  z-index: 10000;
}
/* autoprefixer flex: autoplace */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #ffc107;
}

input:focus + .slider {
  box-shadow: 0 0 1px #ffc107;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

[dir=rtl] .switch {
  margin-left: 16px;
}
[dir=rtl] input:checked + .slider:after {
  -webkit-transform: translateX(-26px);
  transform: translateX(-26px);
}
[dir=rtl] .slider:after {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 30px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
[dir=rtl] .slider.round:after {
  border-radius: 50%;
}

.ignore-direction.switch {
  direction: ltr !important;
  margin-right: 16px;
  margin-left: 16px;
}

.ignore-direction.switch .slider {
  background-color: #ffc107;
  direction: ltr;
}

[dir=ltr] .switch {
  margin-right: 16px;
}
[dir=ltr] input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
[dir=ltr] .slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
}
[dir=ltr] .slider.round:before {
  border-radius: 50%;
}
/* autoprefixer flex: autoplace */
.logo-sections {
  display: flex;
  align-items: center;
}

.card-header-success {
  background: rgba(226, 226, 226, 0.4) no-repeat 95% 50%;
}
.card-header-success .row {
  width: 100%;
}

.master-logo {
  display: -webkit-box;
  background: rgba(226, 226, 226, 0.4) url("/assets/mastercard_2.png") no-repeat 95% 50%;
  width: 42px;
  height: 42px;
}

.qi-logo {
  display: -webkit-box;
  background: rgba(226, 226, 226, 0.4) url("/assets/qi.png") no-repeat 95% 50%;
  width: 32px;
  height: 32px;
}
/* autoprefixer flex: autoplace */
div#threedsChallengeRedirect,
div#redirectTo3ds1AcsSimple {
  height: 125vh !important;
  width: 125vw;
}

.msg {
  padding: 25px;
  max-width: 40em;
  margin: calc(50vh - 61px) 0 0 calc(50vw - 10em);
  align-content: center;
  display: flex;
  flex-direction: column;
}
.msg div {
  margin: auto;
}
.msg .submitButton {
  text-align: center;
}
.msg .redirection {
  height: 50%;
}
.msg .redirection div#threedsChallengeRedirect {
  height: 50vh !important;
  width: calc(60vw - 61px);
}

[dir=ltr] .msg {
  margin: calc(50vh - 61px) 0 0 calc(50vw - 10em);
}

[dir=rtl] .msg {
  margin: calc(50vh - 61px) calc(50vw - 10em) 0 0;
}

@media (max-width: 600px) {
  .msg {
    margin: calc(50vh - 61px) 0 0 calc(33vw - 10em);
  }
  [dir=ltr] .msg {
    margin: calc(50vh - 61px) 0 0 calc(33vw - 10em);
  }
  [dir=rtl] .msg {
    margin: calc(50vh - 61px) calc(33vw - 10em) 0 0;
  }
}
