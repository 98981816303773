/* autoprefixer flex: autoplace */
div#threedsChallengeRedirect,
div#redirectTo3ds1AcsSimple {
  height: 125vh !important; //calc(50vh) !important;
  width: 125vw;
}

.msg {
  padding: 25px;
  max-width: 40em;
  margin: calc(50vh - 61px) 0 0 calc(50vw - 10em);
  align-content: center;
  display: flex;
  flex-direction: column;
  div {
    margin: auto;
  }
  .submitButton {
    text-align: center;
  }
  .redirection {
    height: 50%;
    div#threedsChallengeRedirect {
      height: calc(50vh) !important;
      width: calc(60vw - 61px);
    }
  }
}
[dir='ltr'] .msg {
  margin: calc(50vh - 61px) 0 0 calc(50vw - 10em);
}
[dir='rtl'] .msg {
  margin: calc(50vh - 61px) calc(50vw - 10em) 0 0;
}
@media (max-width: 600px) {
  .msg {
    margin: calc(50vh - 61px) 0 0 calc(33vw - 10em);
  }
  [dir='ltr'] .msg {
    margin: calc(50vh - 61px) 0 0 calc(33vw - 10em);
  }
  [dir='rtl'] .msg {
    margin: calc(50vh - 61px) calc(33vw - 10em) 0 0;
  }
}
