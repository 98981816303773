/* autoprefixer flex: autoplace */
// Colors
$label-colour: #bbb;
$disabled-colour: #ddd;
$toggle-colour: #ffc107;
$white: #fff;
$focus-color: #ff0;

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: $toggle-colour;
}

input:focus + .slider {
  box-shadow: 0 0 1px $toggle-colour;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

[dir='rtl'] {
  .switch {
    margin-left: 16px;
  }
  input:checked + .slider:after {
    -webkit-transform: translateX(-26px);
    -ms-transform: translateX(-26px);
    transform: translateX(-26px);
  }
  .slider:after {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 30px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider.round:after {
    border-radius: 50%;
  }
}

.ignore-direction.switch {
  direction: ltr !important;
  margin-right: 16px;
  margin-left: 16px;
}

.ignore-direction.switch .slider {
  background-color: $toggle-colour;
  direction: ltr;
}

[dir='ltr'] {
  .switch {
    margin-right: 16px;
  }
  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
  .slider:before {
    position: absolute;
    content: '';
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }
  .slider.round:before {
    border-radius: 50%;
  }
}
